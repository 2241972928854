import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import LazyVideo from "../componant/HomeCarosel/LazyVideo";
import "swiper/css";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Header } from "../layout/Header";
import Footer from "../layout/Footer";
import BackPainTestimonial from "../componant/HomeCarosel/BackPainTestimonial";
import { useHomePage } from "../common/hooks";
import { useParams } from "react-router-dom";
import { ImageUrl } from "../common/api";
import Loading from "../componant/Loading";
import LoadingServices from "../componant/Loading-Services";

const BackPainPage = () => {
  const { dataBySlug, getDataBySlug, loading, showMenu } = useHomePage();
  // const [loading, setLoading] = useState(true);

  let { slug, id, name } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  const splitByLastSearch = (str, search) => {
    // Find the last index of the search term
    const lastIndex = str.lastIndexOf(search);

    // If the search term is not found, return the original string in an array
    if (lastIndex === -1) return [str];

    // Split the string into two parts at the last occurrence of the search term
    const firstPart = str.substring(0, lastIndex);
    const secondPart = str.substring(lastIndex + search.length);

    return [firstPart, secondPart];
  };

  const [pageData, setPageData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const dynamicSlug = slug || "home";

      getDataBySlug(dynamicSlug);
    };
    fetchData();
  }, [name]);

  useEffect(() => {
    if (dataBySlug) {
      let find = dataBySlug?.ServiceSectionData?.services.filter(
        (x) => x.serviceDetailsId?._id === id
      );
      if (find?.length !== 0) {
        setPageData(find[0]?.serviceDetailsId);
      }
      // setLoading(false);
    }
  }, [dataBySlug, name]);
  const filePreview = (url) => {
    if (url) {
      let splitUrl = url.split(".").pop();
      if (splitUrl == "mp4") {
        return (
          <video controls className="video">
            <source src={ImageUrl + url} type="video/mp4" />
          </video>
        );
      } else if (splitUrl === "pdf") {
        return (
          <button
            onClick={() => window.open(ImageUrl + url)}
            className="btn btn-primary"
          >
            {url}
          </button>
        );
      } else {
        return <img src={ImageUrl + url} alt="image" className="image" />;
      }
    }
  };

  return (
    <>
      <Header data={dataBySlug?.ServiceSectionData?.services} />
      {loading ? (
        <LoadingServices showMenu={showMenu} />
      ) : (
        <>
          {loading && pageData === undefined ? (
            <h1 className="text-center">Page is in developing</h1>
          ) : (
            <div className="backpain-page">
              <section className="backpain-hero-section text-center">
                <Container>
                  <h1 className="one-text text-secondary mb-0 text-uppercase">
                    {splitByLastSearch(name, " ")[0]}

                    <span className="ps-2 text-primary one-text">
                      {splitByLastSearch(name, " ")[1]}
                    </span>
                  </h1>
                </Container>
              </section>
              <section className="what-back-pain-section">
                <Container>
                  <Row className="align-items-center flex-column-reverse flex-lg-row">
                    <Col lg={6} xs={12} className="mt-3 mt-lg-0">
                      <h2 className="fs-1 fw-bold text-primary">
                        {pageData?.definitionTitle}
                      </h2>
                      <p className="paragraph">{pageData?.definitionContent}</p>
                    </Col>
                    <Col lg={6} xs={12}>
                      <div className="service-video">
                        <LazyVideo
                          controls
                          autoPlay
                          className="video-player w-100 video-img"
                          muted
                          src={ImageUrl + pageData?.definitionImage}
                          type="video/mp4"
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className="symptoms-section">
                <Container>
                  <Row className="align-items-center">
                    <Col lg={6} xs={12}>
                      <div className="symptoms-img text-center text-lg-start">
                        <Image
                          src={ImageUrl + pageData?.symptomsImage}
                          fluid
                          alt="Symptoms"
                        />
                      </div>
                    </Col>
                    <Col lg={6} xs={12} className="mt-3 mt-lg-0">
                      <h2 className="text-primary fs-1 mb-3">
                        {pageData?.symptomsTitle}
                      </h2>
                      <p className="paragraph">{pageData?.symptomsContent}</p>
                      <h5 className="mb-2 fw-bold">Common symptoms include:</h5>
                      <div className="text-secondary diagnosis-list text-bold">
                        <ul>
                          {pageData?.points &&
                            pageData?.points.length > 0 &&
                            pageData?.points.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className="treatment-section">
                <Container>
                  <Row>
                    <h4 className="text-primary heading mb-4 text-center text-uppercase">
                      {pageData?.treatmentTitle}
                    </h4>
                    <Swiper
                      slidesPerView={1.2}
                      spaceBetween={20}
                      grabCursor={true}
                      className="mySwiper"
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      breakpoints={{
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 30,
                        },
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                      }}
                    >
                      {pageData?.treatments?.map((treatment, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <Card className="border-0 treatment-card h-100">
                              <div
                                className="image-video-container"
                                style={{
                                  backgroundColor:
                                    treatment.image.split(".").pop() === "pdf"
                                      ? "#f5f5f5"
                                      : "transparent",
                                }}
                              >
                                {filePreview(treatment.image)}
                                {/* <video
                            src={ImageUrl + treatment.video}
                            autoPlay="true"
                            loop
                            muted
                            className="video"
                          /> */}
                              </div>
                              <Card.Body>
                                <Card.Title>{treatment.title}</Card.Title>
                                <Card.Text className="paragraph">
                                  {treatment.content}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </Row>
                </Container>
              </section>
              <BackPainTestimonial
                video={pageData?.video}
                testimonials={pageData?.testimonialContent}
              />
            </div>
          )}
          <Footer />
        </>
      )}
    </>
  );
};

export default BackPainPage;
