import React, { useState } from "react";
import LazyVideo from "./LazyVideo";
import "swiper/css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import Slider from "react-slick";
import { Container, Row } from "react-bootstrap";
import { ImageUrl } from "../../common/api";
import { Autoplay } from "swiper/modules";

const BackPainTestimonial = ({ video, testimonials }) => {
  console.log("testimonials", testimonials);
  const [setting, setSetting] = useState({
    arrows: true,
    dots: false,
    infinite: testimonials?.length > 1 ? true : false,

    autoplaySpeed: 4000,
    spaceBetween: 20,
    slidesToShow: 1,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    modules: Autoplay,
    slidesToScroll: 1,
  });

  console.log("setting", setting);

  return (
    <>
      <section
        className="testimonial-section testimonial-video-section"
        id="testimonials"
      >
        <Container className="padding-top">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="text-primary sub-heading">Testimonial</h4>
              <h2 className="heading text-secondary d-block mt-2">
                Hear What Our Happy Patients Say
              </h2>
              <div className="row mt-5 justify-content-center">
                {video?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="col-lg-4 col-md-6 col-12 text-center mb-2 mb-md-0"
                    >
                      <div className="testimonial-image">
                        <LazyVideo
                          className="video-player w-100 video-img"
                          controls
                          muted
                          src={ImageUrl + item}
                          type="video/mp4"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* <Row className="mt-5">
            <Swiper
              slidesPerView={1.3}
              spaceBetween={20}
              grabCursor={true}
              className="mySwiper"
              breakpoints={{
                575: {
                  slidesPerView: 2.3,
                },
                768: {
                  slidesPerView: 3.3,
                },
                992: {
                  slidesPerView: 4.3,
                },
              }}
            >
              {video?.map((item, index) => {
                if (item) {
                  return (
                    <SwiperSlide key={index}>
                      <div className="testimonial-image">
                        <LazyVideo
                          className="video-player w-100 video-img"
                          controls
                          muted
                          src={ImageUrl + item}
                          type="video/mp4"
                        />
                      </div>
                    </SwiperSlide>
                  );
                }
              })}
            </Swiper>
          </Row> */}
        </Container>
      </section>
      <section className="testimonial-section testimonial-box">
        <div className="container">
          <div className="mt-5 row">
            <Slider {...setting}>
              {testimonials?.map((testimonial, index) => {
                return (
                  <div
                    key={index}
                    className="testimonial-item col-lg-4 col-md-6 col-12 text-left"
                  >
                    <div className="testimonial-card">
                      <p className="paragraph testimonial-font">
                        {testimonial.content}
                      </p>
                      <h4 className="text-secondary">{testimonial.name}</h4>
                      <h5>
                        ({testimonial.age} Years Old, {testimonial.city})
                      </h5>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default BackPainTestimonial;
