import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy/RefundPolicy";
import TermsCondition from "./pages/TermsCondition/TermsCondition";
import NoPage from "./pages/NoPage/NoPage";
import "./App.css";
import Home from "./pages/Home/home";
import BackPainPage from "./pages/BackPain";
import Popup from "./Popup";
import ThankYouPage from "./pages/Thankyou";

const App = () => {
  return (
    <Fragment>
      <Popup />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/:slug"} element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path={"/:slug/:name/:id"} element={<BackPainPage />} />
        <Route path="/terms-conditions" element={<TermsCondition />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/*" element={<NoPage />} />
        <Route path="/thankyoupage" element={<ThankYouPage />} />
      </Routes>
    </Fragment>
  );
};

export default App;
