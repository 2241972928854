import React, { useEffect, useState } from "react";
import Facebook from "../../src/images/facebook.svg";
import Instagram from "../../src/images/instagram.svg";
import Linkdin from "../../src/images/linkdin.svg";
import foundedBy from "../../src/images/iima.svg";
import startUp from "../../src/images/startUp.svg";
import { Image } from "react-bootstrap";
import logo from "../../src/images/footerLogo.svg";
import WhatsAppButton from "../componant/HomeCarosel/whatsupButton";
import { useHomePage } from "../common/hooks";
import { ImageUrl } from "../common/api";
import ImageLogo from "../../src/images/Image-logo.png";

export default function Footer() {
  const { dataBySlug } = useHomePage();
  const [imageState, setImageState] = useState([]);
  useEffect(() => {
    if (dataBySlug) {
      let image = [...imageState];
      image[0] = dataBySlug?.footerData?.image1
        ? ImageUrl + dataBySlug?.footerData?.image1
        : foundedBy;

      image[1] = dataBySlug?.footerData?.image2
        ? ImageUrl + dataBySlug?.footerData?.image2
        : foundedBy;

      image[2] = dataBySlug?.footerData?.logo
        ? ImageUrl + dataBySlug?.footerData?.logo
        : logo;

      setImageState(image);
    }
  }, [dataBySlug]);

  return (
    dataBySlug?.footerData && (
      <>
        <div className="footer-section" id="contactUs">
          <div className="container py-2">
            <div className="row">
              <div className="col-lg-6 mx-auto image-container">
                <div className="d-flex align-items-center justify-content-center flex-column text-center">
                  <Image className="footer-logo" src={imageState[2]} alt="" />
                  <p className="text-white footer-text mb-4 mt-3">
                    {dataBySlug?.footerData?.content}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-footer-describe">
                <div className="col-lg-3 col-xs-12 col-sm-12 ">
                  <div className="justify-content-center align-items-center p-4 pt-0 text-center d-flex flex-column">
                    <h4 className="text-white footer-sub-heading text-center">
                      {dataBySlug?.footerData?.title1}
                    </h4>
                    <Image src={imageState[0]} alt="foundedBy" />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 col-xs-12">
                  <div className="justify-content-center align-items-center p-4 pt-0 text-center d-flex flex-column">
                    <h4 className="text-white footer-sub-heading text-center mb-4">
                      {dataBySlug?.footerData?.title2}
                    </h4>
                    <Image src={imageState[1]} alt="startUp" className="" />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 col-xs-12 mb-4">
                  <div className="justify-content-start align-items-center p-4 pt-0 text-center d-flex flex-column">
                    <p className="text-white footer-sub-heading">Other Links</p>
                    <ul className="fut-rout service-other-links">
                      <li>
                        <a className="text-white" href="/privacy-policy">
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="/terms-conditions" className="text-white">
                          Terms & Condition
                        </a>
                      </li>
                      <li>
                        <a href="/refund-policy" className="text-white">
                          Refund Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-12 col-xs-12">
                  <div className="footer-contact">
                    <h3 className="text-white footer-sub-heading">Contact</h3>
                    <p className="text-white footer-text mb-1">
                      Name :{" "}
                      <span className="ml-10 text-size-footer">
                        {dataBySlug?.footerData?.name}
                      </span>
                    </p>
                    <p className="text-white footer-text mb-1 text-size-footer">
                      Address : <span>{dataBySlug?.footerData?.address} </span>
                    </p>

                    <h4 className="text-white footer-text text-size-footer">
                      Phone :{" "}
                      <span> +91 - {dataBySlug?.footerData?.phone}</span>
                    </h4>

                    <h4 className=" text-white footer-text text-size-footer">
                      {/* <a href="mailto:curific.care@gmail.com"></a> */}
                      Email : <span>{dataBySlug?.footerData?.email}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 service-nav-footer">
                {/* <div className="social-media"> */}
                <ul className="nav justify-content-center">
                  {dataBySlug?.footerData?.twitter && (
                    <a
                      className="nav-link pb-0"
                      href={dataBySlug?.footerData?.twitter}
                    >
                      <Image
                        className="footer-img"
                        src={Linkdin}
                        alt=" linkedin"
                        aria-label="Linkdin"
                      />
                    </a>
                  )}

                  {dataBySlug?.footerData?.facebook && (
                    <a
                      className="nav-link pb-0"
                      href={dataBySlug?.footerData?.facebook}
                    >
                      <Image
                        className="footer-img"
                        src={Facebook}
                        alt="facebook"
                        aria-label="Facebook"
                      />
                    </a>
                  )}
                  {dataBySlug?.footerData?.instagram && (
                    <a
                      className="nav-link pb-0"
                      href={dataBySlug?.footerData?.instagram}
                    >
                      <Image
                        className="footer-img"
                        src={Instagram}
                        alt=" instagram"
                        aria-label="Instagram"
                      />
                    </a>
                  )}
                </ul>
                {/* </div> */}
                <p className="text-center text-white footer-text">
                  {dataBySlug?.footerData?.footerText}
                </p>
              </div>
              <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 text-center"></div>
            </div>
            {/* <div className="webLink">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 d-flex justify-content-center align-items-center"></div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* <WhatsAppButton phoneNumber={"+917624011050"} /> */}
        <WhatsAppButton
          phoneNumber={dataBySlug?.footerData?.whatsUpMobileNumber}
        />
      </>
    )
  );
}
