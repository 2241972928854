const apiUris = {};

const baseUri = "https://curific.care/api2/v1";
export const ImageUrl = "https://curific.care/resource/";

export const AUTHENTICATE_ROOT = `${baseUri}`;

const HomeSection = {
  get: `${AUTHENTICATE_ROOT}/pageData`,
};

apiUris.homeSection = HomeSection;

export default apiUris;
