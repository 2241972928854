import React from "react";
import { Accordion } from "react-bootstrap";
import { useHomePage } from "../common/hooks";
import { useState } from "react";
import { useEffect } from "react";

const Faqs = () => {
  const { dataBySlug } = useHomePage();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (dataBySlug) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [dataBySlug]);

  return loading
    ? ""
    : dataBySlug?.faqsData && (
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 text-center">
                  <h2 className="text-primary heading mt-5">FAQs</h2>
                  <div
                    className="row text-center mt-5 justify-content-center"
                    style={{ width: "90%", margin: "0 auto" }}
                  >
                    {dataBySlug?.faqsData?.map((faqsData) => (
                      <Accordion defaultActiveKey={null} flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="accordian-header">
                            {faqsData?.question}
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="paragraph somefaq-bg-text mb-0">
                              {faqsData?.answer}
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
};
export default Faqs;
