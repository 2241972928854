import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import closeIcon from "../../images/icons8-close.svg";
import { useNavigate } from "react-router-dom";
import { useHomePage } from "../../common/hooks";

const BookAppointMentPopup = ({ showPopup, setShowPopup }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { setPopupFalse } = useHomePage();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    let dataToSend = {
      ...data,
      status: "NEWCURIFIC",
    };
    let config = {
      method: "post",
      url: "https://curific.care/api/v1/inquiry/WebInquiry",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    };
    try {
      let response = await axios(config);

      if (response.data.success) {
        toast.success(
          "Thank you for your query. Our team will get back to you."
        );
        setPopupFalse(false); // Ensure popup does not reappear
        navigate("/thankyoupage");
      } else {
        toast.error(response?.msg);
      }
    } catch (e) {
      toast.error("Something went wrong. Please try again later.");
    } finally {
      reset();
    }
  };

  return (
    <>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <div
              className="appointment-section appointment-section mt-lg-5 mt-3"
              id="bookAppointment"
            >
              <div className="popup-bg-image">
                <button
                  className="close-btn"
                  onClick={() => setShowPopup(false)}
                >
                  <img src={closeIcon} alt="" height="20px" width="20px" />
                </button>
                <div className="row justify-content-center">
                  <div className="col-12 text-center">
                    <h4 className="text-white heading-title">APPOINTMENT</h4>
                    <h2 className="text-white heading">
                      Book Your Appointment
                    </h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mt-4">
                        <div className="row">
                          <div className="col-12">
                            <input
                              type="text"
                              className={
                                errors.name
                                  ? "border border-danger text-white form-control"
                                  : "text-white form-control"
                              }
                              placeholder="Full Name"
                              {...register("name", { required: true })}
                            />
                          </div>
                          <div className="col-12">
                            <input
                              type="number"
                              className={
                                errors.number
                                  ? "border border-danger text-white form-control"
                                  : "text-white form-control"
                              }
                              placeholder="Phone Number"
                              {...register("number", { required: true })}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <input
                              type="text"
                              className={
                                errors.pain
                                  ? "border border-danger text-white form-control"
                                  : "text-white form-control"
                              }
                              placeholder="Enter Pain"
                              {...register("pain", { required: true })}
                            />
                          </div>
                          <div className="col-12">
                            <input
                              type="text"
                              className={
                                errors.message
                                  ? "border border-danger text-white form-control"
                                  : "text-white form-control"
                              }
                              placeholder="Message"
                              {...register("message", { required: true })}
                            />
                          </div>
                          <div className="col-12">
                            <select
                              className={
                                errors.timeSlot
                                  ? "border border-danger text-white form-select"
                                  : "text-white form-select"
                              }
                              {...register("timeSlot", { required: true })}
                            >
                              <option value="9AM-10AM">9AM - 10AM</option>
                              <option value="10AM-11AM">10AM - 11AM</option>
                              <option value="11AM-12PM">11AM - 12PM</option>
                              <option value="12PM-01PM">1PM - 2PM</option>
                              <option value="02PM-03PM">2PM - 3PM</option>
                              <option value="03PM-04PM">3PM - 4PM</option>
                              <option value="04PM-05PM">4PM - 5PM</option>
                              <option value="05PM-06PM">5PM - 6PM</option>
                              <option value="06PM-07PM">6PM - 7PM</option>
                            </select>
                          </div>
                        </div>
                        <button
                          className="text-white btn-lg btn-block popup-submit-btn heading mt-4 "
                          type="submit"
                        >
                          Book Now
                        </button>
                      </div>
                    </form>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookAppointMentPopup;
