import React, { lazy, useEffect } from "react";
import { Header } from "../../layout/Header";
import Footer from "../../layout/Footer";
import { useHomePage } from "../../../src/common/hooks";
import { useParams } from "react-router-dom";
import Faqs from "../../componant/Faqs";
import Loading from "../../componant/Loading";
import LoadingServices from "../../componant/Loading-Services";

const AboutUs = lazy(() => import("../../componant/HomeCarosel/AboutUs"));
const MainCrosel = lazy(() => import("../../componant/HomeCarosel/MainCrosel"));
const RoadMap = lazy(() => import("../../componant/HomeCarosel/RoadMap"));
const TechBase = lazy(() => import("../../componant/HomeCarosel/TechBase"));
const Services = lazy(() => import("../../componant/HomeCarosel/Services"));
const SomefactAbout = lazy(() =>
  import("../../componant/HomeCarosel/SomefactAbout")
);
const Video = lazy(() => import("../../componant/HomeCarosel/Video"));
const Diagnosis = lazy(() => import("../../componant/HomeCarosel/Diagnosis"));
const Aalayam = lazy(() => import("../../componant/HomeCarosel/Aalayam"));
const WhyCurific = lazy(() => import("../../componant/HomeCarosel/WhyCurific"));
const TreatmentProtocol = lazy(() =>
  import("../../componant/HomeCarosel/TreatmentProtocol")
);
const Appointment = lazy(() =>
  import("../../componant/HomeCarosel/Appointment")
);
const Solution = lazy(() => import("../../componant/HomeCarosel/Solution"));
const Testimonial = lazy(() =>
  import("../../componant/HomeCarosel/Testimonial")
);
const BoardMembers = lazy(() =>
  import("../../componant/HomeCarosel/BoardMembers")
);

const Home = () => {
  const { getDataBySlug, dataBySlug } = useHomePage();
  const [loader, setLoader] = React.useState(true);
  let { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const dynamicSlug = slug || "home";

      getDataBySlug(dynamicSlug);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (dataBySlug) {
      setLoader(false);
    }
  }, [dataBySlug]);

  return (
    <div className="home-page">
      <Header data={dataBySlug?.ServiceSectionData?.services} />
      {loader ? (
        <LoadingServices />
      ) : (
        <>
          <MainCrosel />
          <RoadMap />
          <AboutUs />
          <TechBase />
          <SomefactAbout />
          <Services />
          <Diagnosis />
          <Aalayam />
          <WhyCurific />
          <Video />
          <TreatmentProtocol />
          <Appointment />
          <Solution />
          <Testimonial />
          <Faqs />
          <BoardMembers />
          <Footer />
          <div className="book-demo-card d-block d-sm-none">
            <a href="#bookAppointment" className="btn btn-primary nav-booking">
              Book Your Appointment
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
