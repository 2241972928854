import React from "react";
import Logo from "../../src/images/CURIFIC.gif";

const LoadingServices = ({ showMenu }) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center loading ${
        showMenu ? "class-service-loader1" : "class-service-loader"
      }`}
    >
      <div className="app-loader">
        <img src={Logo} alt="CURIFIC" width="150px" />
      </div>
    </div>
  );
};

export default LoadingServices;
