import React, { useEffect } from "react";
import { Header } from "../../layout/Header";
import Footer from "../../layout/Footer";
import { useHomePage } from "../../common/hooks";
import { useParams } from "react-router-dom";

const PrivacyPolicy = () => {
  const { getDataBySlug, dataBySlug } = useHomePage();
  let { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const dynamicSlug = slug || "home";

      getDataBySlug(dynamicSlug);
    };
    fetchData();
  }, []);

  return (
    <>
      <Header data={dataBySlug?.ServiceSectionData?.services} />
      <div className="privacy-policy-page">
        <div className="sec-div container">
          <h3 className="primary-color privacy-headig">Privacy Policy</h3>
          <p>
            We value the trust you place in Curific Care Private Limited and
            that’s why we insist upon the highest standards of customer
            information privacy and transactions.
          </p>
          <p>
            We, at Curific Care Private Limited are committed to protecting our
            customers’ personal information and/or sensitive personal data and
            strive to maintain the privacy of your personal information.
          </p>
          <p>
            “Personal information” consists of any information that can be used
            by itself to uniquely identify, contact, or locate a person, or can
            be used by other sources to uniquely identify an individual. For the
            above-mentioned purpose, sensitive personal data or information,
            such as medical history has been considered to be a part of personal
            information.
          </p>
          <p>
            By applying to avail of the treatment at Curific Care Private
            Limited, you consent to the terms outlined in this policy.
          </p>

          <h3 className="primary-color privacy-headig">
            1. Data Collection and Usage:
          </h3>

          <h5 className="primary-color">1.1 Personal Information:</h5>
          <p>
            We collect minimal personal information necessary for the
            functionality of the treatment. This includes basic health-related
            information required for the delivery of customized treatment
            services.
          </p>

          <h5 className="primary-color">1.2 Health Information:</h5>

          <p>
            All health-related data is treated with the utmost sensitivity. Your
            health information is encrypted and securely stored with us. We do
            not share this information with third parties without your explicit
            consent.
          </p>

          <h3 className="primary-color privacy-headig">
            2. Security Measures:
          </h3>

          <h5 className="primary-color">2.1 Data Security:</h5>

          <p>
            We employ robust security protocols to safeguard your data from
            unauthorized access, disclosure, or alteration. Regular security
            audits ensure compliance with the highest industry standards.
          </p>

          <h5 className="primary-color">2.2 User Authentication:</h5>

          <p>
            Access to your health records is secured through user
            authentication. Only you and your authorized personnel have access
            to your sensitive health information.
          </p>

          <h3 className="primary-color privacy-headig">
            3. Usage of Information:
          </h3>

          <h5 className="primary-color">3.1 Purpose of Data:</h5>
          <p>
            The data collected is used exclusively for delivering personalized
            health services, including remote diagnosis, reporting,
            tele-consultation, and tele-treatment.
          </p>

          <h5 className="primary-color">3.2 Third-party Access:</h5>

          <p>
            We do not share your personal or health information with third
            parties for commercial purposes without your explicit consent.
          </p>

          <h3 className="primary-color privacy-headig">4. User Control:</h3>

          <h5 className="primary-color">4.1 Data Access and Modification:</h5>

          <p>
            You have control over your data. You can modify your preferences at
            any point during the treatment.
          </p>

          <h3 className="primary-color privacy-headig">5. Policy Updates:</h3>

          <h5 className="primary-color">5.1 Notification of Changes:</h5>

          <p>
            Any changes to this privacy policy will be communicated through our
            official website. We encourage users to review this policy
            regularly.
          </p>

          <h3 className="primary-color privacy-policy-sub-heading">
            Cookie Policy
          </h3>

          <p>
            A cookie is a small file that seeks your permission to be placed on
            your computer’s hard drive. Upon agreement, the file is added,
            allowing the cookie to analyze web traffic or notify you when you
            visit a specific site. Cookies enable web applications to respond to
            you individually, tailoring operations based on your preferences,
            likes, and dislikes. At Curific Care Private Limited, we utilize
            traffic log cookies to identify pages visited, aiding in the
            analysis of webpage traffic and the enhancement of our website to
            meet customer needs. This information is used solely for statistical
            analysis, and once the analysis is complete, the data is removed
            from the system. Cookies play a crucial role in providing an
            improved website experience by allowing us to monitor the pages you
            find useful and those you don’t. It’s important to note that cookies
            do not provide access to your computer or any personal information
            beyond what you choose to share with us. You have the option to
            accept or decline cookies. While most web browsers automatically
            accept cookies, you can modify your browser settings to decline them
            if you prefer. It’s worth noting that this choice may limit your
            ability to fully utilize all features of the website.
          </p>

          <h3 className="primary-color privacy-policy-sub-heading">
            How We Use Cookies
          </h3>

          <p>
            At Curific Care Private Limited, we employ cookies to store visitor
            preferences and capture user-specific details about the pages
            accessed. This information enables us to personalize and customize
            our webpage content according to visitors’ browser type or other
            transmitted data through their browsers. Additionally, Google,
            acting as a third-party vendor, utilizes cookies, specifically the
            DART cookie, to deliver ads to our site’s visitors based on their
            activities across our site and other websites on the Internet. Users
            have the option to opt out of the DART cookie usage by visiting the
            Google ad and content network privacy policy, accessible at &nbsp;
            <a href="http://www.google.com/privacy_ads.html" target="_blank">
              http://www.google.com/privacy_ads.html
            </a>
            .
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
