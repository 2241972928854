import React, { useState, createContext, useContext } from "react";
import { executeApi } from "./base-context";
import { LoaderContext } from "./loader-context";
import { NotificationContext } from "./notification-context";
import { HomeSectionAPI } from "../api";
import { useParams } from "react-router-dom";

export const HomePageContext = createContext({});

export const HomePageProvider = ({ children }) => {
  const { showLoading, loading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const [dataBySlug, setDataBySlug] = useState();
  const [popupFalse, setPopupFalse] = useState(true);
  const [showMenu, setShowMenu] = useState(true);

  const executeHomeSectionAPI = async (apiFunction, request) => {
    const response = await executeApi(
      apiFunction,
      request,
      showLoading,
      addMessage
    );
    return response;
  };

  const getDataBySlug = async (request) => {
    try {
      showLoading(false);
      const res = await executeHomeSectionAPI(HomeSectionAPI.get, request);

      if (res.success) {
        setDataBySlug(res.data);

        showLoading(false);
      } else {
        showLoading(false);
        addMessage("data get Failed");
      }
    } catch (error) {
      showLoading(false);
      addMessage("Failed to fetch Data");
    }
  };
  const contextValue = {
    loading,
    popupFalse,
    showMenu,
    setShowMenu,
    setPopupFalse,
    dataBySlug,
    getDataBySlug,
  };

  return (
    <HomePageContext.Provider value={contextValue}>
      {children}
    </HomePageContext.Provider>
  );
};
