import React from "react";

import {
  LoaderProvider,
  NotificationProvider,
  HomePageProvider,
} from "./";
import Compose from "./compose";

export const AppContextWrapper = ({ children }) => {
  const providers = [LoaderProvider, NotificationProvider, HomePageProvider];
  return <Compose components={providers}>{children}</Compose>;
};
