import React, { useEffect } from "react";
import { Header } from "../../layout/Header";
import Footer from "../../layout/Footer";
import { useHomePage } from "../../common/hooks";
import { useParams } from "react-router-dom";

const RefundPolicy = () => {
  const { getDataBySlug, dataBySlug } = useHomePage();
  let { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const dynamicSlug = slug || "home";

      getDataBySlug(dynamicSlug);
    };
    fetchData();
  }, []);

  return (
    <>
      <Header data={dataBySlug?.ServiceSectionData?.services} />
      <div className="refund-policy-page ">
        <div className="sec-div container">
          <div className="refund-policy-sub-sec">
            <h3 className="primary-color">Refund Policy</h3>
            <ul>
              <li>
                The treatment cost of Curific Private Limited is divided into 3
                subsections.
                <ol>
                  <li>The Treatment service charge</li>
                  <li>Shipping Charges for the medical equipment</li>
                  <li>Security Deposit for medical equipment</li>
                </ol>
              </li>
              <li>
                <b>Shipping Charge</b> for medical equipment is{" "}
                <b>non-refundable</b> under all circumstances, even after the
                cancellation of the treatment.
              </li>
              <li>
                If the patient cancels the treatment before the beginning of the
                first session, you will get a refund of the entire amount
                (excluding shipping charges & pertinence to no damage to the
                medical equipment).
              </li>
              <li>
                Please note that the refund will only be initiated after the
                medical equipment has reached Curific Care Private Limited and
                has been checked thoroughly for any prone damage.
              </li>
              <li>
                If during/before/after the cancellation process, any damage is
                incurred on the medical equipment, then the security deposit
                amount will be deducted as per the repair/damage cost. This will
                vary as per the level of damage, cost of repair, and different
                situations.
              </li>
              <li>
                If the medical equipment is in good condition/ in the same
                condition as it was sent, only then will the patient be eligible
                for a full refund.
              </li>
            </ul>
          </div>
          <div className="cancellation-policy-sub-sec">
            <h3 className="primary-color">Cancellation Policy</h3>
            <ul>
              <li>
                Curific Care Private Limited provides a treatment that lasts and
                is limited to up to 10 sessions. No additional sessions
                following the course of these 10 sessions will be provided under
                any circumstance.
              </li>
              <li>
                The patient will be eligible to cancel a treatment before the
                beginning of the first session by the Curific Doctor. No
                cancellation will be accepted after the patient has availed of
                Curific services even in one session.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RefundPolicy;
