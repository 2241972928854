import React, { useState, useCallback, createContext, useContext } from "react";

export const LoaderContext = createContext({});

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoadingONE = (REQ) => {
    setLoading(REQ);
  };

  const contextValue = {
    loading,
    showLoading: useCallback((isLoading) => {
      showLoadingONE(isLoading);
    }, []),
  };

  return (
    <LoaderContext.Provider value={contextValue}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => useContext(LoaderContext);
