import React, { useState } from "react";
import { Image, NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/logo.webp";
import { useLocation, useNavigate } from "react-router-dom";
import BookAppointMentPopup from "../componant/HomeCarosel/BookAppointmentPopup";
import { useHomePage } from "../common/hooks";
import { useEffect } from "react";
export const Header = ({ data }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { setShowMenu } = useHomePage();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowSize.width > 990) {
      setShowMenu(false);
    } else {
      setShowMenu(showMobileMenu);
    }
  }, [showMobileMenu, windowSize]);
  const handleShowPopup = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleShowMenu = (e) => {
    const documentValue = document.getElementsByClassName(
      "navbar-toggler collapsed"
    );
    if (documentValue.length > 0) {
      setShowMobileMenu(true);
    } else {
      setShowMobileMenu(false);
    }
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary"
      sticky="top"
    >
      <Container>
        <Navbar.Brand href="/">
          <Image src={logo} alt="Logo" height="60" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={handleShowMenu}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              className="navbar-padding"
              href={location.pathname !== "/" ? "/" : "#home"}
              smooth="true"
            >
              Home
            </Nav.Link>
            <Nav.Link
              className="navbar-padding"
              href={location.pathname !== "/" ? "/" : "#about"}
              smooth="true"
            >
              About Us
            </Nav.Link>
            <NavDropdown
              title="Services"
              id="nav-dropdown"
              show={!isMobile ? show : undefined}
              onMouseEnter={!isMobile ? () => setShow(true) : undefined}
              onMouseLeave={!isMobile ? () => setShow(false) : undefined}
              onClickCapture={isMobile ? () => setShow((prev) => !prev) : undefined}
              onClick={() => {
                const section = document.getElementById("services");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              }}
              className="navbar-padding"
            >
              {data &&
                data.length !== 0 &&
                data.map((item) => (
                  <NavDropdown.Item
                    className="nav-link navbar-padding pe-4"
                    key={item?.serviceDetailsId?._id}
                    onClick={() => {
                      setShow(false);
                      navigate(
                        `/home/${item?.title}/${item?.serviceDetailsId?._id}`,
                        { state: { item } }
                      );
                    }}
                  >
                    {item.title}
                  </NavDropdown.Item>
                ))}
            </NavDropdown>

            <Nav.Link
              className="navbar-padding"
              href={location.pathname !== "/" ? "/" : "#why-curific"}
              smooth="true"
            >
              Why Curific
            </Nav.Link>

            <Nav.Link
              className="navbar-padding"
              href={location.pathname !== "/" ? "/" : "#testimonials"}
              smooth="true"
            >
              Healing Stories
            </Nav.Link>
            <Nav.Link
              className="navbar-padding"
              href={location.pathname !== "/" ? "/" : "#contactUs"}
              smooth="true"
            >
              Contact Us
            </Nav.Link>
            {location.pathname !== "/" ? (
              <a
                onClick={handleShowPopup}
                className="btn btn-primary nav-booking d-none d-sm-block btn-appointment"
              >
                Book Your Appointment
              </a>
            ) : (
              <a
                href={"#bookAppointment"}
                className="btn btn-primary nav-booking d-none d-sm-block btn-appointment"
              >
                Book Your Appointment
              </a>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
      {showPopup && (
        <BookAppointMentPopup
          setShowPopup={setShowPopup}
          showPopup={showPopup}
        />
      )}
    </Navbar>
  );
};
